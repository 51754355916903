<template>
    <div class="orders-screen">
        <div class="data-table">
        <GenericDataTable
          :blocos="true"
          class="mt-10"
          :header="header"
          detailsUrl="/admin/"
          :action="'GET_DENTISTS'"
          :addBtn="false"
          :addEditDelete="false"
          :addShow="false"
          icon="mdi-doctor"
          :filters="filters"
          :pequeno="false"
          :grande="true"
          :addSearch="true"
          @show="show($event)"
        />
        </div>
    </div>
</template>

<script>
//import OrdersDataTable from "../../components/DataTable/OrdersDataTable";
import GenericDataTable from "../../components/DataTable/GenericDataTable";


export default ({
  name: 'DentistList',
  components: {
      //OrdersDataTable,
      GenericDataTable,

  },
  data() {
    return {
      access_token: '',
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "id",
          sortable: false,
          filterable: true,
          value: "user_id",
          align: "center",
        },
        {
          text: "Dentista",
          sortable: true,
          filterable: true,
          value: "name",
          align: "center",
        },
        {
          text: "E-mail",
          sortable: true,
          filterable: true,
          value: "email",
          align: "center",
        },
        {
          text: "CRO",
          sortable: true,
          filterable: true,
          value: "cro",
          align: "center",
        },
        {
          text: "Cidade",
          sortable: true,
          filterable: true,
          value: "cidade",
          align: "center",
        },
        {
          text: "UF",
          sortable: true,
          filterable: true,
          value: "uf",
          align: "center",
        },
        {
          text: "Contato",
          sortable: true,
          filterable: true,
          value: "dentist_user.messenger_contacts",
          align: "center",
        },
        {
          text: "Informações do Banco",
          sortable: true,
          filterable: true,
          value: "dentist_user.bank_information",
          align: "center",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          filterable: false,
          value: "options",
        },
      ],

      filters: {},
      item: {}
    }
  },

  mounted(){
  },

  methods: {
    show(id) {
      localStorage.showId = id
      this.$router.push({name: 'DentistDetails', params: {id: id}})
      // this.show()
    },
  },

})

</script>

<style scoped>

.orders-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
