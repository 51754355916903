import axios from "../../plugins/axios";

export const statusStore = {
    state: () => ({
        statusList: [],
        status: {},
    }),
    mutations: {
        setOrderStatus(state, payload) {
            state.status = payload;
        },
        setOrderStatusList(state, payload) {
            state.statusList = payload;
        },
    },
    actions: {
        GET_STATUS_LIST({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/order/order-status", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setOrderStatusList', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_STATUS({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/order/order-status/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setOrderStatus', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_STATUS({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/order/order-status', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setOrderStatus', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_STATUS({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/order/order-status/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setOrderStatus', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_STATUS(context, item) {
            return new Promise((resolve) => {
                axios.delete('/order/order-status/' + item.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
                axios.get("/order/order-status")
                    .then(response => {
                        var values = response.data.data.length
                        for(var i=0 ; i<values ; i++){
                            if(response.data.data[i].position > item.position){
                                let obj = {
                                    id: response.data.data[i].id,
                                    position: response.data.data[i].position - 1,
                                  }
                                axios.put('/order/order-status/' + obj.id, obj)
                                .then(response => {
                                    let values = response.data.data
                                    commit('setOrderStatus', values);
                                    resolve(response)
                                })
                                .catch(error => error)
                            }
                        }
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default statusStore;