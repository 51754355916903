<template>
  <div>
    <div class="text-center d-flex pb-4">
      <v-btn @click="back">
        Voltar
      </v-btn>
    </div>
    <p></p>
    <div>
    <v-expansion-panels
      v-model="panel"
      multiple
      class="table"
    >
      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados do Laboratório</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold"><template>
            <v-img
            :src="perfil_image[0]"
            aspect-ratio="3"
            contain
            >
            </v-img>
          </template>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">ID: </p>{{ id }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">ID do usuário: </p>{{ user_id }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Descrição: </p>{{ description }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Serviços Digitais: </p>{{ digital_services }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Serviços: </p>{{ services }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Contatos: </p>{{ messenger_contacts }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Gerrente Técnico: </p>{{ technical_manager }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Informações do Banco: </p>{{ bank_information }}</p>
          <v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Imagens</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <template>
            <v-row>
              <v-col
                v-for="(image, i) in getURLImages(images)"
                :key="i"
                cols="4"
              >
                <v-img
                :src="image"
                aspect-ratio="1"
                contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
      

    </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default ({
  name: 'LabDetails',
  components: {

  },
  data() {
    return {
      panel: [0, 1],

      items: 2,

      id:'',
      user_id:'',
      description:'',
      digital_services:'',
      services:'',
      messenger_contacts:'',
      technical_manager:'',
      bank_information:'',
      images:'',
      perfil_image:'',
    }
  },

  mounted(){
    this.getData(this.$route.params.id)
    console.log(this.$route.params.id)
  },

  methods: {
    ...mapActions(['GET_LAB']),

    getData(id){
      this.GET_LAB(localStorage.showId)
      .then( res => {
        this.id = res.data.data.id,
        this.user_id = res.data.data.user_id,
        this.description = res.data.data.description,
        this.digital_services = res.data.data.digital_services,
        this.services = res.data.data.services,
        this.messenger_contacts = res.data.data.messenger_contacts,
        this.technical_manager = res.data.data.technical_manager,
        this.bank_information = res.data.data.bank_information,
        this.images = res.data.data.images,
        this.perfil_image = res.data.data.perfil_image
        }
      )
    },

    getURLImages(images){
        console.log("imgs: ", images)
        return JSON.parse(images)
    },


    all () {
      this.panel = [...Array(this.items).keys()].map((k, i) => i)
    },
    none () {
      this.panel = []
    },
    back (){
      this.$router.push({name: 'LabList'})
    },

    
  },
})
</script>

<style scoped>

.table >>> .v-expansion-panels {
  width: 100% !important
}


</style>
