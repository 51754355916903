<template>
    <div
        class="textPrimary menuItemBranco"
        @click="$emit('loggout'), $emit('selected', routeName)"
    >
        <div
            class="items"
        >
            <v-icon
                :color="'primary'"
                align="left"
            >
                {{ icon }}
            </v-icon>
            <h3 class="text-item textPrimary">
                {{ label }}
            </h3>
            <div></div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LogoutMenu',
    props: {
        label: {
            default: 'Nome menu'
        },
        icon: {
            default: 'lar la-bell'
        },
        routeName: {
            // default: 'Aditivo',
        },
    },
    data() {
        return {
            selected: false,
        }
    },
}
</script>

<style scoped>
.menuItem {
    background-color: #3C2E88;
    padding: 10px;
    margin: 10px;
}

.menuItemBranco {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    margin: 10px;
    height: 2.3rem;
    display: flex;

}

.menuItem:hover {
    background-color: #004ea4;
    border-radius: 10px;
    cursor: pointer;
}

.menuItemBranco:hover {
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
}


.items {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.text-item {
    font-size: 1.2em;
    font-weight: normal;
    text-align: left;
    position: absolute;
    margin-left: 40px;
}

.textWhite {
    color: white;
}

.textPrimary {
    color: #0074F0;
}

</style>