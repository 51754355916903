import axios from "../../plugins/axios";

export const labsStore = {
    state: () => ({
        dentists: [],
        dentist: {},
    }),
    mutations: {
        setLabs(state, payload) {
            state.labs = payload;
        },
        
        setLab(state, payload) {
            state.lab = payload;
        },
    },
    actions: {
        GET_LABS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/laboratories/all", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setLabs', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_LAB({commit}, id) {
            return new Promise((resolve) => {
                axios.get("/laboratories/lab/"+id)
                    .then(response => {
                        let values = response.data.data
                        commit('setLab', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default labsStore;