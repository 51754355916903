<template>
  <div class="body">
    <div class="body-content">
      <v-card
          height="560"
          elevation="3"
          class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendBank($event)"
          />
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                @click.native="$router.back()"
            />
            <DefaultButton
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../../components/Buttons/DefaultButton";
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'CreateBank',
  components: {
    DefaultButton,
    FormFields
  },
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Banco',
      inputsValue: {
        id: null,
        name: null,
        code: null,
      },
      text_button: 'Cadastrar',
      flag: false
    }
  },
  mounted() {
    if(this.$route.params.isEditing) {
      this.getBank(this.$route.params.id)
    }else {
      this.initFields()
    }
  },
  methods: {
    ...mapActions(['GET_BANK', 'CREATE_BANK', 'EDIT_BANK']),
    initFields() {
      this.fields = {
        name: {
          identificacao_interna: 'name',
          field: 'TextField',
          cols: 12,
          label: 'Nome',
          dinamicRules: [(v) => !!v || "Preencha o nome"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.name,
        },
        code: {
          identificacao_interna: 'code',
          field: 'TextField',
          cols: 12,
          label: 'Código',
          dinamicRules: [(v) => !!v || "Preencha o código"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.code,
        },
      }
    },
    getBank(id) {
      this.text_button = 'Salvar'
      let obj = {
        id: id
      }
      this.GET_BANK(obj)
          .then( res => {
                this.inputsValue = res.data.data
                this.initFields()
              }
          )
    },
    sendBank(event) {

      if (!this.$route.params.isEditing) {
        let obj = {
          name: event.values.name,
          code: event.values.code
        }
        this.CREATE_BANK(obj)
            .then( res => {
              if (res.status === 201) {
                this.$router.push({name: 'Banks'})
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        let obj = {
          id: this.$route.params.id,
          name: event.values.name,
          code: event.values.code
        }
        console.log(obj)
        this.EDIT_BANK(obj).then( res => {
          if (res.status === 200) {
            this.$router.push({name: 'Banks'})
          }
        })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }
    }
  }


}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  height: 100% !important;
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>