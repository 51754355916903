import Login from "../../views/Login/Login";
import ForgotPassword from "../../views/Login/ForgotPassword";
import PasswordRecovery from "../../views/Login/PasswordRecovery";
import EmailConfirm from "../../views/Users/EmailConfirm";

export const loginRouter = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/recuperar-senha',
        name: 'RedefinirSenha',
        component: ForgotPassword
    },
    {
        path: '/redefinir-senha',
        name: 'ReescreverSenha',
        component: PasswordRecovery
    },
    {
        path: '/confirma-email/:key',
        name: 'EmailConfirm',
        component: EmailConfirm,
    },
]

export default loginRouter;