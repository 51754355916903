<template>
  <div class="color-screen">
    <div class="data-table">
      <GenericDataTable
          :blocos="true"
          class="mt-10"
          :header="header"
          detailsUrl="/admin/"
          :action="'GET_COLORS'"
          :refresh="refreshTable"
          routeCreate="CreateColorTable"
          routeEdit="EditColorTable"
          icon="mdi-invert-colors"
          :filters="filters"
          :pequeno="false"
          :grande="true"
          @options="edit($event)"
          @delete="deletar($event)"
      />
      <modal
          name="email-modal"
          width="800"
          height="700"
          :clickToClose="false"
          :adaptive="true"
          class="email-modal"
      >
        <!--<Modal @closeModal="hide($event)" :item="item"/>-->
      </modal>
    </div>
  </div>
</template>


<script>
//import Modal from "../../components/Modal/Modal";
import GenericDataTable from "../../components/DataTable/GenericDataTable";
import {mapActions} from "vuex";

export default {
  name: 'Color',
  components: {
    GenericDataTable,
    //Modal
  },
  data() {
    return {
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "ID",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Tabela",
          sortable: true,
          filterable: true,
          value: "name",
          align: "left",
        },
        {
          text: "Cores",
          sortable: true,
          filterable: true,
          value: "colors",
          align: "left",
        },
        {
          text: "Opções",
          align: "center",
          sortable: false,
          filterable: false,
          value: "options",
        },
      ],
      refreshTable: 0,
      filters: {},
      item: {}
    }
  },
  methods: {
    ...mapActions(['GET_COLOR', 'DELETE_COLOR']),

    edit(id) {
      this.$router.push({name: 'EditColorTable', params: {id: id, isEditing: true}})
      // this.show()
    },
    deletar(id) {
      this.DELETE_COLOR(id).then(
        () => {
          this.refreshTable++
        }
      )
    },
    show() {
      this.$modal.show('email-modal');
    },
    hide(value) {
      this.$modal.hide('email-modal');
      if (value) {
        this.alert = true;
        this.refreshTable++;
      }
    }
  }
}
</script>

<style scoped>
.color-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>