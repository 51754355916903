import axios from "../../plugins/axios";

export const paymentStore = {
    state: () => ({
        payments: [],
        payment: {},
    }),
    mutations: {
        setPayment(state, payload) {
            state.payment = payload;
        },
        setPayments(state, payload) {
            state.payments = payload;
        },
    },
    actions: {
        GET_PAYMENTS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/admin/payment_methods", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        for(var i=0 ; i<values.length ; i++){
                            if(values[i].type == 0){
                                values[i].type = 'À VISTA'
                            }
                            if(values[i].type == 1){
                                values[i].type = 'A PRAZO'
                            }
                        }
                        commit('setPayments', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_PAYMENT({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/admin/payment_methods/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setPayment', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_PAYMENT({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/payment_methods', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setPayment', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_PAYMENT({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/payment_methods/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setPayment', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_PAYMENT(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/payment_methods/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default paymentStore;