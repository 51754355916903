import Vue from 'vue'
import Vuex from 'vuex'
import loginStore from './modules/login'
import userStore from "./modules/users";
import termStore from "./modules/terms_of_use";
import bankStore from "./modules/banks";
import paymentStore from "./modules/payments";
import integrationStore from "./modules/integrations";
import groupStore from "./modules/groups";
import toothStore from "./modules/teeths";
import statusStore from "./modules/order_status";
import colorStore from "./modules/color_table";
import ordersStore from "./modules/orders";
import dentistsStore from "./modules/dentist";
import labsStore from "./modules/lab";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login: loginStore,
    users: userStore,
    terms: termStore,
    banks: bankStore,
    payments: paymentStore,
    integrations: integrationStore,
    groups: groupStore,
    teeths: toothStore,
    order_status: statusStore,
    color_table: colorStore,
    orders: ordersStore,
    dentist: dentistsStore,
    lab: labsStore,

  },
  state: {
    loading: [],
  },
  mutations: {
    beginLoading(state) {
      state.loading.push({
        loading: true
      })
    },
    finishLoading(state) {
      state.loading.pop()
    },
  },
  actions: {
  },
  getters: {
    loading: state => state.loading.length > 0,
  }
})
