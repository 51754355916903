<template>
  <div class="body">
    <div class="body-content">
      <v-card
          height="560"
          elevation="3"
          class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendColor($event)"
          />
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                @click.native="$router.back()"
            />
            <DefaultButton
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../../components/Buttons/DefaultButton";
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'CreateColorTable',
  components: {
    DefaultButton,
    FormFields
  },
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Tabela de Cores',
      inputsValue: {
        id: null,
        name: null,
        colors: null,
        lista: null,
      },
      text_button: 'Cadastrar',
      flag: false
    }
  },
  mounted() {
    if(this.$route.params.isEditing) {
      this.getColor(this.$route.params.id)
    }else {
      this.initFields()
    }
  },
  watch:{
    'inputsValue.lista'(){
      this.inputsValue.colors = []

      for(var i=0 ; i<this.inputsValue.lista.length ; i++){
        this.inputsValue.colors[i] = {id: i, name: this.inputsValue.lista[i]}
      }
    },
  },
  methods: {
    ...mapActions(['GET_COLOR', 'CREATE_COLOR', 'EDIT_COLOR']),
    initFields() {
      this.fields = {
        name: {
          identificacao_interna: 'name',
          field: 'TextField',
          cols: 12,
          label: 'Nome da Tabela',
          dinamicRules: [(v) => !!v || "Preencha o nome da tabela"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.name,
        },
        lista: {
          identificacao_interna: 'lista',
          field: 'combobox',
          cols: 12,
          label: 'Cores',
          dinamicRules: [(v) => !!v || "Preencha as cores"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.lista,
        },
      }
    },
    getColor(id) {
      this.text_button = 'Salvar'
      let obj = {
        id: id
      }
      this.GET_COLOR(obj)
          .then( res => {
                this.inputsValue.id = res.data.data.id
                this.inputsValue.name = res.data.data.name
                this.inputsValue.lista = res.data.data.colors
                this.initFields()
              }
          )
    },
    sendColor(event) {

      if (!this.$route.params.isEditing) {
        let obj = {
          name: event.values.name,
          colors: this.inputsValue.colors,
        }
        this.CREATE_COLOR(obj)
            .then( res => {
              if (res.status === 201) {
                this.$router.push({name: 'ColorTable'})
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        let obj = {
          id: this.$route.params.id,
          name: event.values.name,
          colors: this.inputsValue.colors,
        }
        console.log(obj)
        this.EDIT_COLOR(obj).then( res => {
          if (res.status === 200) {
            this.$router.push({name: 'ColorTable'})
          }
        })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }
    }
  }


}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  height: 100% !important;
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>