<template>
  <v-col :cols="cols">
    <div class="container-input">
      <v-combobox
      v-model="text"
      chips
      :label="label"
      multiple
      @input="$emit('update:value', text)"
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            @click="select"
          >
            <strong>{{ item }}</strong>&nbsp;
          </v-chip>
      </template>
      </v-combobox>
    </div>
  </v-col>
</template>

<script>

export default {
  name: "Combobox",
  components: {},
  props: {
    valueField: {},
    outlined: {},
    label: {},
    cols: {},
    label: {},
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      showAppend: false,
      text: this.valueField || '',
    }
  },
  directives: {},
  watch: {
    clearValue() {
      this.text = '';
    },
    valueField() {
      this.text = this.valueField;
      this.$emit('update:value', this.text);
    }
  },
  mounted() {
    if (this.valueField) {
      this.text = this.valueField;
      this.$emit('update:value', this.text);
    }
  },
}
</script>

<style scoped>

</style>