<template>
  <v-btn
    v-if="!show"
    class="standard-button"
    :class="{azul: azul, branco: branco}"
  >
    {{ text }}
  </v-btn>
</template>

<script>

export default {
  name: 'DefaultButton',
  props: {
    text: {
      default: 'Salvar'
    },
    azul: {
      default: false,
    },
    branco: {
      default: false
    },
    show: {
      default: ''
    }
  }
}
</script>

<style scoped>

.standard-button {

}

.azul {
  background: #3d3de8 !important;
  color: white !important;
}

.branco {
  background: #E5EAF0 !important;
  color: #3d3de8 !important;
}

</style>