import configAxios from 'axios';
import store from "../store";


const axios = configAxios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
    }
});
//
axios.interceptors.request.use(config => {
    store.commit('beginLoading')
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config;
});
//
axios.interceptors.response.use(
    (response) => {
        store.commit('finishLoading')
        return response;

    },
    (error) => {
        store.commit('finishLoading')
        return error
    }
);

axios.defaults.validateStatus = (status) => {
    return status >= 200 && status < 300;
};


export default axios;
