import axios from "../../plugins/axios";

export const userStore = {
    state: () => ({
        users: [],
        user: {},
    }),
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setUsers(state, payload) {
            state.users = payload;
        },
    },
    actions: {
        GET_USERS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get('/admin/portal/users', {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setUsers', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_USER({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/admin/user/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setUser', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_USER({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/create-user-portal', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setUser', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        EDIT_USER({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/user-update/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setUser', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_USER(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/user/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default userStore;