<template>
  <div class="template">
    <section class="container-page">
      <div class="container-menu">
        <MenuBar class="menu"></MenuBar>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
// import Header from "../../components/Menu/Header";
import MenuBar from "../../components/Menu/MenuBar";

export default {
  name: 'BooklabTemplate',
  components: {
    // Header,
    MenuBar
  },
  data() {
    return {
      botao: null,
    }
  },
}
</script>

<style scoped>
.template {
  width: 100%;
  min-height: 100vh;
  background: white !important;
}

.header {
  z-index: 1;
}

.container-page {
  z-index: 7;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
}

.container-menu {
  min-width: 320px;
  width: 320px;
  max-width: 320px;
}

.menu {
  width: 320px;
}

.content {
  /*margin-top: 80px;*/
  padding: 20px;
  display: flex;
  flex: 1;
}

@media only screen and (max-width: 900px) {
  .menu, .container-menu {
    display: none;
  }

}
</style>