<template>
  <v-col
    :cols="cols"
  >
    <div class="container-input">
      <v-textarea
          :disabled="disabled"
          :rules="rules ? rules : []"
          :label="label"
          v-model="text"
          :height="height"
          :type="type"
          :placeholder="placeholder"
          @input="$emit('update:value', text)"
          @keyup.enter="$emit('onEnter', text)"
          :outlined="outlined"
      />
    </div>
  </v-col>
</template>

<script>

export default {
  name: 'TextArea',
  props: {
    label: {},
    outlined: {},
    placeholder: {},
    type: {},
    cols: {},
    height:{},
    rules: {},
    clearValue: {},
    valueField: {},
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      text: this.valueField || '',
    }
  },
  watch: {
    clearValue() {
      this.text = '';
    },
    valueField() {
      this.text = this.valueField;
      this.$emit('update:value', this.text);
    }
  },
  mounted() {
    if (this.valueField) {
      this.text = this.valueField;
      this.$emit('update:value', this.text);
    }
  },
}
</script>