import axios from "../../plugins/axios";

export const colorStore = {
    state: () => ({
        colors: [],
        color: {},
    }),
    mutations: {
        setColor(state, payload) {
            state.Color = payload;
        },
        setColors(state, payload) {
            state.Colors = payload;
        },
    },
    actions: {
        GET_COLORS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/dentists/color-table", {params: filters})
                    .then(response => {
                        for(var i=0;i<response.data.data.length;i++){
                            for(var j=0;j<response.data.data[i].colors.length;j++){
                                response.data.data[i].colors[j] = response.data.data[i].colors[j].name
                            }
                        }
                        let values = response.data.data
                        commit('setColors', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_COLOR({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/dentists/color-table/' + obj.id)
                    .then(response => {
                        for(var j=0;j<response.data.data.colors.length;j++){
                            response.data.data.colors[j] = response.data.data.colors[j].name
                        }
                        let values = response.data.data
                        commit('setColor', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_COLOR({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/dentists/color-table', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setColor', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_COLOR({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/dentists/color-table/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setColor', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_COLOR(context, id) {
            return new Promise((resolve) => {
                axios.delete('/dentists/color-table/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default colorStore;