import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "./plugins/axios";
import 'vuetify/dist/vuetify.min.css'
// import './assets/scss/app.scss'
import VueMask from "v-mask";
import VueJSModal from "vue-js-modal";

Vue.prototype.$http = axios


// Sets the default url used by all of this axios instance's requests
//Alterar a url abaixo para puxar a o link da
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// axios.defaults.baseURL = "http://api-hml-booklab.schaffenlab.com/api"
axios.defaults.headers.get['Accept'] = 'application/json'

Vue.use(VueJSModal)
Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
