import { render, staticRenderFns } from "./TermsOfUse.vue?vue&type=template&id=53bac2de&scoped=true&"
import script from "./TermsOfUse.vue?vue&type=script&lang=js&"
export * from "./TermsOfUse.vue?vue&type=script&lang=js&"
import style0 from "./TermsOfUse.vue?vue&type=style&index=0&id=53bac2de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53bac2de",
  null
  
)

export default component.exports