import axios from "../../plugins/axios";

export const termStore = {
    state: () => ({
        terms: [],
        term: {},
    }),
    mutations: {
        setTerm(state, payload) {
            state.term = payload;
        },
        setTerms(state, payload) {
            state.terms = payload;
        },
    },
    actions: {
        GET_TERMS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/admin/acceptance-term", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setTerms', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_TERM({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/admin/acceptance-term/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setTerm', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_TERM({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/acceptance-term', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setTerm', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_TERM({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/acceptance-term/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setTerm', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_TERM(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/acceptance-term/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default termStore;