<template>
  <div id="app">
    <v-app>
      <div class="novoLoading" v-if="loading">
        <div class="quadradinho">
          <div class="loader"></div>
        </div>
      </div>
      <router-view />
    </v-app>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters(["loading"])
  }
}

</script>

<style lang="scss">

.nav-link > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.novoLoading {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quadradinho {
  width: 11em;
  height: 11em;
  background: #3d3de8;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3d3de8;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: +1;
  align-items: center;
  opacity: 1 !important;
  box-shadow: 0 0 10px black;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
