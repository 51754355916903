<template>
  <div>
    <div class="text-center d-flex pb-4">
      <v-btn @click="all">
        Abrir Todos
      </v-btn>
      &nbsp;&nbsp;&nbsp;
      <v-btn @click="none">
        Fechar Todos
      </v-btn>
      &nbsp;&nbsp;&nbsp;
      <v-btn @click="back">
        Voltar
      </v-btn>
    </div>
    <p></p>
    <div>
    <v-expansion-panels
      v-model="panel"
      multiple
      class="table"
    >
      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados do Dente</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">Posição do Dente: </p>{{ teeth_position }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Cor do Dente: </p>{{ teeth_color }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Evidência: </p>{{ evidence }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Informações Adicionais: </p>{{ additional_information }}</p>
          <v-divider></v-divider>
          <p class="font-weight-bold">Imagens:</p>
          <template>
            <v-row>
              <v-col
                v-for="(images, i) in images"
                :key="i"
                cols="4"
              >
                <v-img
                :src="images"
                aspect-ratio="2.5"
                contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados da Entrega</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">Data da Entrega: </p>{{ this.delivery_time }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Período de Entrega: </p>{{ delivery_period }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">País: </p>{{ address_country }}</p>  
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Estado: </p>{{ address_state }}</p> 
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Cidade: </p>{{ address_city }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Rua: </p>{{ address_city }}</p>  
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Número: </p>{{ address_number }}</p>  
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Complemento: </p>{{ address_complement }}</p>
          <v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados do Paciente</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ patient_id }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Nome: </p>{{ patient_name }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Data de Nascimento: </p>{{ patient_dob }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Email: </p>{{ patient_email }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Telefone: </p>{{ patient_phone }}</p>
          <v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Forma de Pagamento</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ payment_id }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Nome: </p>{{ payment_name }}</p>
          <v-divider></v-divider>
          <p><p class="font-weight-bold">Tipo: </p>{{ payment_type }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Status: </p>{{ payment_status }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Prestações Máximas: </p>{{ payment_maxInstallmentns }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Descrição: </p>{{ payment_description }}</p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados do Dentista</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ dentist_id }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Nome: </p>{{ dentist_name }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">ID do Usuário: </p>{{ dentist_userID }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Email: </p>{{ dentist_email }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">CRO: </p>{{ dentist_cro }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Informação Bancária: </p>{{ dentist_bankInformation }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Contato Comercial: </p>{{ dentist_comercial }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">WhatsApp: </p>{{ dentist_wpp }}</p><v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Descrição do Serviço</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ service_id }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Nome: </p>{{ service_name }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Categoria: </p>{{ service_category }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Descrição: </p>{{ service_description }}</p><v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Dados do Laboratório</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ laboratory_id }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">ID do Usuário: </p>{{ laboratory_userID }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Descrição: </p>{{ laboratory_description }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Informação Bancária: </p>{{ laboratory_bankInformation }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Serviços Digitais: </p>{{ laboratory_digitalServices }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Contato(s): </p>{{ laboratory_messengerContacts }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Serviços: </p>{{ laboratory_services }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Gerente Técnico: </p>{{ laboratory_technicalManager }}</p><v-divider></v-divider>
          <p class="font-weight-bold">Imagens do Laboratório:</p>
          <template>
            <v-row>
              <v-col
                v-for="(laboratory_images, i) in laboratory_images"
                :key="i"
                cols="4"
              >
                <v-img
                :src="laboratory_images"
                aspect-ratio="2.5"
                contain
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h1>Serviços Prestados pelo Laboratório</h1></v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <p><p class="font-weight-bold">ID: </p>{{ laboratory_serviceServicesID }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Nome: </p>{{ laboratory_serviceName }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Nome do Procedimento: </p>{{ laboratory_serviceServiceName }}</p><v-divider></v-divider>
          <p><p class="font-weight-bold">Valores Regulares: </p>{{ laboratory_serviceRegularPrices }}</p><v-divider></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>

      

    </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default ({
  name: 'OrderDetails',
  components: {

  },
  data() {
    return {
      panel: [0],

      items: 8,
      
      teeth_position: '',
      teeth_color: '',
      images: '',
      imagesLenght: '',
      evidence: '',
      additional_information: '',

      delivery_time: '',
      delivery_period: '',
      address_city: '',
      address_complement: '',
      address_country: '',
      address_number: '',
      address_state: '',
      address_street: '',

      patient_id: '',
      patient_name: '',
      patient_dob: '',
      patient_email: '',
      patient_phone: '',

      payment_id: '',
      payment_name: '',
      payment_type: '',
      payment_status: '',
      payment_maxInstallmentns: '',
      payment_description: '',

      dentist_id: '',
      dentist_name: '',
      dentist_userID: '',
      dentist_email: '',
      dentist_cro: '',
      dentist_bankInformation: '',
      dentist_comercial: '',
      dentist_wpp: '',

      service_category: '',
      service_description: '',
      service_id: '',
      service_name: '',

      laboratory_id: '',
      laboratory_userID: '',
      laboratory_description: '',
      laboratory_bankInformation: '',
      laboratory_digitalServices: '',
      laboratory_images: '',
      laboratory_messengerContacts: '',
      laboratory_services: '',
      laboratory_technicalManager: '',

      laboratory_serviceName: '',
      laboratory_serviceServicesID: '',
      laboratory_serviceRegularPrices: '',
      laboratory_serviceServiceName: '',

    }
  },

  mounted(){
    this.getData(this.$route.params.id)
  },

  methods: {
    ...mapActions(['GET_ORDER']),

    getData(id){
      this.GET_ORDER(localStorage.showId)
      .then( res => {
            this.delivery_period = res.data.data.delivery_period,
            this.additional_information = res.data.data.additional_information,

            this.address_city = res.data.data.delivery_address.address_city,
            this.address_complement = res.data.data.delivery_address.address_complement
            this.address_country = res.data.data.delivery_address.address_country
            this.address_number = res.data.data.delivery_address.address_number
            this.address_state = res.data.data.delivery_address.address_state
            this.address_street = res.data.data.delivery_address.address_street

            this.delivery_time = res.data.data.delivery_time,

            this.patient_id = res.data.data.patient.id,
            this.patient_name = res.data.data.patient.name,
            this.patient_dob = res.data.data.patient.date_of_birth,
            this.patient_email = res.data.data.patient.email,
            this.patient_phone = res.data.data.patient.phone_number,

            this.payment_id = res.data.data.payment_method.id,
            this.payment_name = res.data.data.payment_method.name,
            this.payment_type = res.data.data.payment_method.type,
            this.payment_status = res.data.data.payment_method.status,
            this.payment_maxInstallmentns = res.data.data.payment_method.max_installments,
            this.payment_description = res.data.data.payment_method.description,

            this.dentist_id = res.data.data.dentist.id,
            this.dentist_name = res.data.data.dentist.name,
            this.dentist_userID = res.data.data.dentist.user_id,
            this.dentist_email = res.data.data.dentist.email,
            this.dentist_cro = res.data.data.dentist.cro,
            this.dentist_bankInformation = res.data.data.dentist.bank_information,
            this.dentist_comercial = res.data.data.dentist.messenger_contacts.comercial,
            this.dentist_wpp = res.data.data.dentist.messenger_contacts.whatsapp,

            this.evidence = res.data.data.evidence,
            this.images = res.data.data.images,

            this.laboratory_id = res.data.data.laboratory.id,
            this.laboratory_userID = res.data.data.laboratory.user_id,
            this.laboratory_description = res.data.data.laboratory.description,
            this.laboratory_bankInformation = res.data.data.laboratory.bank_information,
            this.laboratory_digitalServices = res.data.data.laboratory.digital_services,
            this.laboratory_images = res.data.data.laboratory.images,
            this.laboratory_messengerContacts = res.data.data.laboratory.messenger_contacts,
            this.laboratory_services = res.data.data.laboratory.services,
            this.laboratory_technicalManager = res.data.data.laboratory.technical_manager,

            this.laboratory_serviceName = res.data.data.laboratory_service.laboratory_name,
            this.laboratory_serviceServicesID = res.data.data.laboratory_service.laboratory_services_id,
            this.laboratory_serviceRegularPrices = res.data.data.laboratory_service.regular_prices,
            this.laboratory_serviceServiceName = res.data.data.laboratory_service.service_name,
            
            this.service_category = res.data.data.service.category,
            this.service_description = res.data.data.service.description,
            this.service_id = res.data.data.service.id,
            this.service_name = res.data.data.service.name

            this.teeth_position = res.data.data.teeth_details.position,
            this.teeth_color = res.data.data.teeth_details.color
          }
      )
    },

    all () {
      this.panel = [...Array(this.items).keys()].map((k, i) => i)
    },
    none () {
      this.panel = []
    },
    back (){
      this.$router.push({name: 'Orders'})
    },

    
  },
})
</script>

<style scoped>

.table >>> .v-expansion-panels {
  width: 100% !important
}


</style>
