<template>
  <div class="body">
    <div class="body-content">
      <v-card
          height="560"
          elevation="3"
          class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-order-bool-descending-variant</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendOrderStatus($event)"
          />
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                @click.native="$router.back()"
            />
            <DefaultButton
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../../components/Buttons/DefaultButton";
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'CreateOrderStatus',
  components: {
    DefaultButton,
    FormFields
  },
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Status de Ordem',
      inputsValue: {
        id: null,
        name: null,
        position: null,
      },
      text_button: 'Cadastrar',
      flag: false
    }
  },
  mounted() {
    if(this.$route.params.isEditing) {
      this.getOrderStatus(this.$route.params.id)
    }else {
      this.initFields()
    }
  },
  methods: {
    ...mapActions(['GET_STATUS_LIST','GET_STATUS', 'CREATE_STATUS', 'EDIT_STATUS']),
    initFields() {
      this.GET_STATUS_LIST()
          .then( res => {
              if(this.inputsValue.id == null)
                this.inputsValue.position = res.data.data.length + 1
                this.fields = {
                  name: {
                    identificacao_interna: 'name',
                    field: 'TextField',
                    cols: 12,
                    label: 'Nome',
                    dinamicRules: [(v) => !!v || "Preencha o nome"],
                    disabled: false,
                    outlined: false,
                    valueField: this.inputsValue.name,
                  },
                  position: {
                    identificacao_interna: 'position',
                    field: 'TextField',
                    cols: 12,
                    label: 'Posição',
                    disabled: true,
                    outlined: false,
                    valueField: this.inputsValue.position,
                  },
                }
              }
          )
    },
    getOrderStatus(id) {
      this.text_button = 'Salvar'
      let obj = {
        id: id
      }
      this.GET_STATUS(obj)
          .then( res => {
                this.inputsValue = res.data
                this.initFields()
              }
          )
    },
    sendOrderStatus(event) {
      if (!this.$route.params.isEditing) {
        let obj = {
          name: event.values.name,
          position: event.values.position,
          status: true,
        }
        this.CREATE_STATUS(obj)
            .then( res => {
              if (res.status === 201) {
                this.$router.push({name: 'OrderStatus'})
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        let obj = {
          id: this.$route.params.id,
          name: event.values.name,
          position: event.values.position,
        }
        this.EDIT_STATUS(obj).then( res => {
          if (res.status === 200) {
            this.$router.push({name: 'OrderStatus'})
          }
        })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }
    }
  }


}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  height: 100% !important;
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>