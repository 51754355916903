<template>
  <div class="body">
    <div class="body-content">
      <v-card
          height="560"
          elevation="3"
          class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              :disabled="!edit"
              @update:values="inputsValue = $event"
              @checkValidity="sendIntegration($event)"
          />
          <v-row class="form-fields fields px-3" v-for="n in contCampos">
            <v-col cols="12" sm="3" md="3">
              <v-text-field
              label="Campo"
              v-model="campo[n-1].key"
              :disabled="!edit"
              />
            </v-col>
            <v-col cols="12" sm="9" md="9">
              <v-text-field
                label="Valor"
                v-model="campo[n-1].valor"
                :disabled="!edit"
              />
            </v-col>
          </v-row>
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                class="ml-4"
                @click.native="$router.back()"
            />
            <DefaultButton
                :show="!edit"
                text="Remove Campo"
                :branco="true"
                class="ml-4"
                @click.native="removeCampo()"
            />
            <DefaultButton
                :show="!edit"
                text="Add Campo"
                :branco="true"
                class="ml-4"
                @click.native="addLinhaCampo()"
            />
            <DefaultButton 
                :show="edit" 
                text="Editar" 
                :azul="true" 
                class="ml-4" 
                @click.native="edit=!edit"
            />
            <DefaultButton
                :show="!edit"
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../../components/Buttons/DefaultButton";
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'CreateIntegration',
  components: {
    DefaultButton,
    FormFields
  },
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Integração',
      edit: false,
      inputsValue: {
        id: null,
        name: null,
        json: null,
      },
      json: '',
      objJson: [],
      campo: [{
        key: '',
        valor: '',
      }],
      contCampos: 1,
      text_button: 'Cadastrar',
      flag: false
    }
  },
  mounted() {
    if(this.$route.params.isEditing) {
      this.getIntegration(this.$route.params.id)
    }else {
      this.initFields()
    }
  },

  methods: {
    ...mapActions(['GET_INTEGRATION', 'CREATE_INTEGRATION', 'EDIT_INTEGRATION']),
    initFields() {
      this.fields = {
        name: {
          identificacao_interna: 'name',
          field: 'TextField',
          cols: 12,
          label: 'Nome',
          dinamicRules: [(v) => !!v || "Preencha o nome"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.name,
        },
      }
    },
    addLinhaCampo(){
      if(this.contCampos<=20){
        this.campo.push({key: '', valor: ''},)
        console.log(this.campo)
        this.contCampos++
      }
    },
    removeCampo(){
      if(this.contCampos>1){
        this.campo.pop()
        this.contCampos--;
        }
    },
    getIntegration(id) {
      this.text_button = 'Salvar'
      let obj = {
        id: id
      }
      console.log('get')
      this.GET_INTEGRATION(obj)
        .then(res => {
            this.inputsValue.name = res.data.name
            var qtd = 0;
            var key;
            for(key in res.data.fields[res.data.name]) {
                if(qtd != 0){
                  this.addLinhaCampo()
                }
                console.log(key)
                this.campo[qtd].key = key
                this.campo[qtd].valor = res.data.fields[res.data.name][key]
                qtd++;
            }
            this.initFields()
          })
          .catch( error => {
            console.log(error)
          })
    },
    sendIntegration(event) {
      this.objJson = {}
      for(var i=0 ; i<this.campo.length ; i++){
        this.objJson[this.objJson, this.campo[i].key] = this.campo[i].valor;
      }
      this.objJson = {[this.inputsValue.name]: this.objJson};
      this.inputsValue.json = this.objJson
      if (!this.$route.params.isEditing) {
        let obj = {
          name: this.inputsValue.name,
          fields: this.inputsValue.json
        }
        console.log(this.inputsValue.json)
        this.CREATE_INTEGRATION(obj)
            .then( res => {
              if (res.status === 200) {
                //this.$router.push({name: 'Integrations'})
                this.$router.back()
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        let obj = {
          id: this.$route.params.id,
          name: this.inputsValue.name,
          fields: this.inputsValue.json
        }
        console.log(this.inputsValue.json)
        this.EDIT_INTEGRATION(obj)
        .then( res => {
          if (res.status === 200) {
            //this.$router.push({name: 'Integrations'})
            this.$router.back()
          }
        })
        .catch( error => {
          console.log(error)
          this.flag = true
        })
      }
    }
  }


}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  height: 100% !important;
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>