<template>
  <div class="user-screen">
    <div class="data-table">
      <GenericDataTable
          :blocos="true"
          class="mt-10"
          :header="header"
          detailsUrl="/admin/"
          :action="'GET_GROUPS'"
          :refresh="refreshTable"
          routeCreate="CreateGroup"
          routeEdit="EditGroup"
          icon="mdi-account-group-outline"
          :filters="filters"
          :pequeno="false"
          :grande="true"
          @options="edit($event)"
          @delete="deletar($event)"
      />
    </div>
  </div>
</template>


<script>

import GenericDataTable from "../../components/DataTable/GenericDataTable";
import {mapActions} from "vuex";
export default {
  name: 'Groups',
  components: {GenericDataTable},
  data() {
    return {
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "Nome",
          sortable: true,
          filterable: true,
          value: "name",
          align: "left",
        },
        {
          text: "Opções",
          align: "center",
          sortable: false,
          filterable: false,
          value: "options",
        },
      ],
      refreshTable: 0,
      filters: {}
    }
  },
  methods: {
    ...mapActions(['DELETE_GROUP']),

    edit(id) {
      this.$router.push({name: 'EditGroup', params: {id: id, isEditing: true}})
    },
    deletar(id) {
      this.DELETE_GROUP(id).then(
        () => {
          this.refreshTable++
        }
      )
    }
  }
}
</script>

<style scoped>

.user-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>