import BooklabTemplate from "../../views/Template/BooklabTemplate";
import Users from "../../views/Users/Users";
import CreateUser from "../../views/Users/CreateUser";
import TermsOfUse from "../../views/TermsOfUse/TermsOfUse";
import CreateTerm from "../../views/TermsOfUse/CreateTerm";
import Banks from "../../views/Banks/Banks";
import CreateBank from "../../views/Banks/CreateBank";
import Payments from "../../views/Payments/Payments";
import CreatePayment from "../../views/Payments/CreatePayment";
import Integrations from "../../views/Integrations/Integrations";
import CreateIntegration from "../../views/Integrations/CreateIntegrations";
import Groups from "../../views/Groups/Groups";
import CreateGroup from "../../views/Groups/CreateGroup";
import Teeths from "../../views/Teeths/Teeths";
import CreateTooth from "../../views/Teeths/CreateTooth";
import OrderStatus from "../../views/OrderStatus/OrderStatus";
import CreateOrderStatus from "../../views/OrderStatus/CreateOrderStatus";
import ColorTable from "../../views/ColorTable/ColorTable";
import CreateColorTable from "../../views/ColorTable/CreateColorTable";
import Orders from "../../views/Orders/Orders";
import OrderDetails from "../../views/Orders/OrderDetails";
import LabList from "../../views/ViewLab/LabList"
import LabDetails from "../../views/ViewLab/LabListDetails"
import DentistList from "../../views/ViewDentist/DentistList"


export const booklab_portal = [
    {
        path: '/',
        name: 'Home',
        component: BooklabTemplate,
        meta: {
            requiresAuthAdmin: true,
        },
        redirect: '/users',
        children: [
            {
                path: '/users',
                name: 'Users',
                component: Users,
                meta: {},
            },
            {
                path: '/create-user',
                name: 'CreateUser',
                component: CreateUser,
                meta: {},
            },
            {
                path: '/edit-user/:id',
                name: 'EditUser',
                component: CreateUser,
                meta: {},
            },
            {
                path: '/acceptance-term',
                name: 'TermsOfUse',
                component: TermsOfUse,
                meta: {},
            },
            {
                path: '/create-acceptance-term',
                name: 'CreateTerm',
                component: CreateTerm,
                meta: {},
            },
            {
                path: '/acceptance-term/:id',
                name: 'EditTerm',
                component: CreateTerm,
                meta: {},
            },
            {
                path: '/banks',
                name: 'Banks',
                component: Banks,
                meta: {},
            },
            {
                path: '/create-bank',
                name: 'CreateBank',
                component: CreateBank,
                meta: {},
            },
            {
                path: '/create-bank/:id',
                name: 'EditBank',
                component: CreateBank,
                meta: {},
            },
            {
                path: '/payments',
                name: 'Payments',
                component: Payments,
                meta: {},
            },
            {
                path: '/create-payment',
                name: 'CreatePayment',
                component: CreatePayment,
                meta: {},
            },
            {
                path: '/create-payment/:id',
                name: 'EditPayment',
                component: CreatePayment,
                meta: {},
            },
            {
                path: '/integrations',
                name: 'Integrations',
                component: Integrations,
                meta: {},
            },
            {
                path: '/create-Integration',
                name: 'CreateIntegration',
                component: CreateIntegration,
                meta: {},
            },
            {
                path: '/create-Integration/:id',
                name: 'EditIntegration',
                component: CreateIntegration,
                meta: {},
            },
            {
                path: '/teeths',
                name: 'Teeths',
                component: Teeths,
                meta: {},
            },
            {
                path: '/create-tooth',
                name: 'CreateTooth',
                component: CreateTooth,
                meta: {},
            },
            {
                path: '/create-tooth/:id',
                name: 'EditTooth',
                component: CreateTooth,
                meta: {},
            },
            {
                path: '/order-status',
                name: 'OrderStatus',
                component: OrderStatus,
                meta: {},
            },
            {
                path: '/create-order-status',
                name: 'CreateOrderStatus',
                component: CreateOrderStatus,
                meta: {},
            },
            {
                path: '/create-order-status/:id',
                name: 'EditOrderStatus',
                component: CreateOrderStatus,
                meta: {},
            },
            {
                path: '/color-table',
                name: 'ColorTable',
                component: ColorTable,
                meta: {},
            },
            {
                path: '/create-color-table',
                name: 'CreateColorTable',
                component: CreateColorTable,
                meta: {},
            },
            {
                path: '/create-color-table/:id',
                name: 'EditColorTable',
                component: CreateColorTable,
                meta: {},
            },
            {
                path: '/groups',
                name: 'Groups',
                component: Groups,
                meta: {},
            },
            {
                path: '/create-group',
                name: 'CreateGroup',
                component: CreateGroup,
                meta: {},
            },
            {
                path: '/edit-group/:id',
                name: 'EditGroup',
                component: CreateGroup,
                meta: {},
            },
            {
                path: '/Pedidos',
                name: 'Orders',
                component: Orders,
                meta: {},
            },
            {
                path: '/DetalhesPedido',
                name: 'OrderDetails',
                component: OrderDetails,
                meta: {},
            },
            {
                path: '/ListaLaboratorios',
                name: 'LabList',
                component: LabList,
                meta: {},
            },
            {
                path: '/DetalhesLaboratorios',
                name: 'LabDetails',
                component: LabDetails,
                meta: {},
            },
            {
                path: '/ListaDentistas',
                name: 'DentistList',
                component: DentistList,
                meta: {},
            },
        ]
    },
]

export default booklab_portal;