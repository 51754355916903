import axios from "../../plugins/axios";

export const loginStore = {
    state: () => ({
        authStatus: false,
        token: localStorage.getItem("token") || null,
        user: {},
    }),
    mutations: {
        AUTH_SUCCESS(state, {
            token,
            user
        }) {
            state.authStatus = true
            state.token = token
            state.user = user
        },
        LOGOUT(state) {
            state.authStatus = false
            state.token = ''
        },
    },
    actions: {

        LOGIN_PROCESS({commit}, payload) {
            return new Promise((resolve) => {
                axios.post('/auth/login', payload)
                    .then(response => {
                        let token = response.data.data.token;
                        // let user = response.data.user;
                        localStorage.setItem('token', token)
                        // localStorage.setItem('user', user)
                        commit('AUTH_SUCCESS', {token})
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        LOGOUT_PROCESS({commit}) {
            localStorage.clear()
            commit('LOGOUT')
        },

        SEND_EMAIL_RECOVERY({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/recover-password', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setUser', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        SEND_PASSWORD_RECOVERY({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/restore', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setUser', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default loginStore;