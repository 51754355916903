<template>
  <div class="body">
    <div class="body-content">
      <v-card
          height="560"
          elevation="3"
          class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendPayment($event)"
          />
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                @click.native="$router.back()"
            />
            <DefaultButton
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../../components/Buttons/DefaultButton";
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'CreatePayment',
  components: {
    DefaultButton,
    FormFields
  },
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Formas de Pagamento',
      inputsValue: {
        id: null,
        name: null,
        type: null,
        max_installments: null,
        description: null,

      },
      text_button: 'Cadastrar',
      flag: false
    }
  },
  mounted() {
    if(this.$route.params.isEditing) {
      this.getPayment(this.$route.params.id)
    }else {
      this.initFields()
    }
  },
  methods: {
    ...mapActions(['GET_PAYMENT', 'CREATE_PAYMENT', 'EDIT_PAYMENT']),
    initFields() {
      this.fields = {
        name: {
          identificacao_interna: 'name',
          field: 'TextField',
          cols: 7,
          label: 'Nome',
          dinamicRules: [(v) => !!v || "Preencha o nome"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.name,
        },
        type: {
          identificacao_interna: 'type',
          field: 'SelectField',
          items: [
            {name:'À VISTA', value:0},
            {name:'A PRAZO', value:1}
          ],
          valueField: {name:'--SELECIONE--', value:0},
          cols: 3,
          label: 'Tipo',
          dinamicRules: [(v) => !!v || "Selecione o tipo"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.type,
        },
        max_installments: {
          identificacao_interna: 'max_installments',
          field: 'TextField',
          cols: 2,
          type: 'number',
          label: 'Parcelas',
          dinamicRules: [(v) => !!v || "Preencha o número máximo de parcelas"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.max_installments,
        },
        description: {
          identificacao_interna: 'description',
          field: 'TextField',
          cols: 12,
          label: 'Descrição',
          dinamicRules: [(v) => !!v || "Preencha a descrição"],
          disabled: false,
          outlined: false,
          valueField: this.inputsValue.description,
        },
      }
    },
    getPayment(id) {
      this.text_button = 'Salvar'
      let obj = {
        id: id
      }
      this.GET_PAYMENT(obj)
          .then( res => {
                this.inputsValue = res.data.data
                this.initFields()
              }
          )
    },
    sendPayment(event) {

      if (!this.$route.params.isEditing) {
        let obj = {
          name: event.values.name,
          type: event.values.type.value,
          max_installments: event.values.max_installments,
          description: event.values.description
        }
        this.CREATE_PAYMENT(obj)
            .then( res => {
              if (res.status === 201) {
                this.$router.push({name: 'Payments'})
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        let obj = {
          id: this.$route.params.id,
          name: event.values.name,
          type: event.values.type.value,
          max_installments: event.values.max_installments,
          description: event.values.description
        }
        console.log(obj)
        this.EDIT_PAYMENT(obj).then( res => {
          if (res.status === 200) {
            this.$router.push({name: 'Payments'})
          }
        })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }
    }
  }


}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  height: 100% !important;
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>