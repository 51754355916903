import axios from "../../plugins/axios";

export const toothStore = {
    state: () => ({
        teeths: [],
        tooth: {},
    }),
    mutations: {
        setTooth(state, payload) {
            state.tooth = payload;
        },
        setTeeths(state, payload) {
            state.teeths = payload;
        },
    },
    actions: {
        GET_TEETHS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/dentists/teeths", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setTeeths', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_TOOTH({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/dentists/teeths/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setTooth', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_TOOTH({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/dentists/teeths', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setTooth', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_TOOTH({ commit }, obj) {
            return new Promise((resolve) => {
                console.log('rota', obj)
                const formData = new FormData();
                formData.append("id", obj.id);
                formData.append("name", obj.name);
                formData.append("position", obj.position);
                formData.append("number", obj.number);
                formData.append("selected_image", obj.selected_image);
                formData.append("standard_image", obj.standard_image);
                axios.post('/dentists/teeths/' + obj.id, formData)
                    .then(response => {
                        let values = response.data.data
                        commit('setTooth', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_TOOTH(context, id) {
            return new Promise((resolve) => {
                axios.delete('/dentists/teeths/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default toothStore;