<template>
  <v-col :cols="cols">
    <div class="container-input">
      <v-row>
        <v-col cols="12" sm="2" md="2" class="text-center">
          <img :src="valueField" id="output" style="width: 100px; height: 100px;"/>
        </v-col>
        <v-col cols="12" sm="10" md="10">
          <v-file-input :label="label"
            :class="{'has-rules': rules, 'withButton': withButton, 'borderSuccess': borderSuccess, 'borderDanger': borderDanger, 'darkStyle' : darkStyle}"
            :prepend-inner-icon="prependInnerIcon" v-model="file" :type="type" @input="$emit('update:value', file)"
            @keyup.enter="$emit('onEnter', file)" :outlined="outlined" />
        </v-col>
      </v-row> 
    </div>
  </v-col>
</template>

<script>

export default {
  name: "FileField",
  components: {},
  props: {
    label: {},
    outlined: {},
    placeholder: {},
    type: {},
    cols: {},
    prependInnerIcon: {},
    appendIcon: {},
    rules: {},
    clearValue: {},
    valueField: {},
    disabled: {
      default: false,
    },
    withButton: {},
    borderSuccess: {},
    borderDanger: {},
    darkStyle: {},
  },
  data() {
    return {
      showAppend: false,
      file: {},
    }
  },
  watch: {
    clearValue() {
      this.file = {};
    },

    file() {
      var output = document.getElementById('output');
      if (this.file) {
        output.src = URL.createObjectURL(this.file);
        var reader = new FileReader();
        reader.onload = function () {
          var output = document.getElementById('output');
          output.src = reader.result;
        };
        reader.readAsDataURL(this.file);
        this.$emit('update:value', this.file);
      } else {
        output.src = this.valueField;
      }
    },
  },
}
</script>

<style scoped>

</style>