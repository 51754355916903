<template>
  <div class="main-login">
    <div class="left" style="color: white">
      <div class="box-text">
        <h2>Seja Bem Vindo!</h2>
        <div style="height: 0.2rem; width: 4rem; background-color: white" />
        <h6 style="margin-top: 2rem">
          Nossa empresa piriri pororo oferece aos dentistase aos n
        </h6>
      </div>
    </div>
    <div class="right">
      <div class="right-form">

        <div class="text_color">
          <h1>Redefenir a senha</h1>
          <div style="height: 0.2rem; width: 4rem; background-color: #3d3de8" />
        </div>
        <div class="form">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendPassword($event)"
          />
          <div v-if="alert">
            <h4 style="color: red">As senhas devem ser iguais!</h4>
          </div>
          <v-btn
              color="#3d3de8"
              rounded
              class="button-login white--text"
              @click="validate"
          >
            Enviar
          </v-btn>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormFields from "../../components/FormFields";
import {mapActions} from "vuex";

export default {
  name: 'PasswordRecovery',
  components: {
    FormFields
  },
  data() {
    return {
      validateFields: 0,
      fields: {},
      inputsValue: {
        password: null,
        confirm_password: null
      },
      alert: false,
    }
  },
  mounted() {
    this.initFields()
  },
  methods: {
    ...mapActions(["SEND_PASSWORD_RECOVERY"]),

    initFields() {
      this.fields = {
        password: {
          identificacao_interna: 'senha',
          field: 'TextField',
          cols: 12,
          label: 'Senha',
          type: 'password',
          outlined: false,
          appendIcon: ["mdi-lock-outline"],
          dinamicRules: [(v) => !!v || "Preencha a senha"],
          disabled: false,
          valueField: this.inputsValue.password,
        },
        confirm_password: {
          identificacao_interna: 'email',
          field: 'TextField',
          cols: 12,
          label: 'Digite a senha novamente',
          type: 'password',
          outlined: false,
          appendIcon: ["mdi-lock-outline"],
          dinamicRules: [(v) => !!v || "Preencha a senha"],
          disabled: false,
          valueField: this.inputsValue.confirm_password,
        },

      }
    },
    validate() {
      this.validateFields++
    },
    sendPassword(event) {
      if(event.isValid){
        if (this.inputsValue.password === this.inputsValue.confirm_password) {
          //ALTerAR AQUI
          let obj = {
            key: this.$route.query.key,
            password: this.inputsValue.password
          }
          this.SEND_PASSWORD_RECOVERY(obj)
          this.$router.push({name: 'Login'})
        }else {
          this.alert = true
        }
      }
    }
  }

}
</script>

<style scoped>

h1 {
  text-align: center;
}

.main-login {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.main-login .right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-login .right .text_color {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -5rem;
  margin-bottom: 5rem;
}

.main-login .left {
  background-color: #3d3de8;
  width: 50%;
}

.right-form {
  width: 60%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-login {
  width: 95%;
  margin-top: 2rem;
}

.button-voltar {
  width: 95%;
  margin-top: 1rem;
}

.box-text {
  padding: 15rem 5rem 5rem 5rem;
}

@media only screen and (max-width: 845px) {

  .left {
    display: none;
    /*visibility: hidden;*/
  }

  .main-login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .right-form {
    width: 100%;
  }

  .main-login .right {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

</style>