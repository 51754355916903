import axios from "../../plugins/axios";

export const groupStore = {
    state: () => ({
        groups: [],
        group: {},
    }),
    mutations: {
        setGroup(state, payload) {
            state.group = payload;
        },
        setGroups(state, payload) {
            state.groups = payload;
        },
    },
    actions: {
        GET_GROUPS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get('/admin/groups', {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setGroups', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_GROUP({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/admin/groups/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setGroup', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_GROUP({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/groups', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setGroup', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        EDIT_GROUP({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/groups/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setGroup', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_GROUP(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/groups/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default groupStore;