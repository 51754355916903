<template>
  <div>
    <div class="headerLogo">
      <img class="logo" src="../../assets/booklab-logo-header.png"/>
    </div>
    <div class="body">
      <div class="body-content">
        <v-card
          height="400"
          elevation="3"
          class="card"
        >
          <div class="header">
            <div style="display: flex;flex-direction: row">
              <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
              <h2 style="color: white">
                {{ textHeader }}
              </h2>
            </div>
            <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
          </div>
          <div class="content">
            <FormFields
                :fields="fields"
                :validate="validateFields"
                @update:values="inputsValue = $event"
                @checkValidity="sendUser($event)"
            />
            <div class="buttons mt-5">
              <DefaultButton
                :text="text_button"
                :azul="true"
                class="ml-4"
                @click.native="validateFields++"
              />
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>

import FormFields from "../../components/FormFields";
import DefaultButton from "../../components/Buttons/DefaultButton";
import axios from "../../plugins/axios";

export default {
  name: 'CreateUser',
  components: {DefaultButton, FormFields},
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Crie sua senha',
      inputsValue: {
        key: null,
        password: null
      },
      text_button: 'Criar Senha',
      flag: false,
      groups: [],
    }
  },
  props: {
    isEditing: {
      default: false
    }
  },
  async mounted() {
      await this.initFields()
  },
  methods: {
    initFields() {
        this.fields = {
          password: {
            identificacao_interna: 'password',
            field: 'TextField',
            type: 'password',
            cols: 12,
            label: 'Nova Senha',
            dinamicRules: [(v) => !!v || "Preencha a senha"],
            disabled: false,
            outlined: false,
            valueField: this.inputsValue.password,
          },
          confirm_password: {
            identificacao_interna: 'confirm_password',
            field: 'TextField',
            type: 'password',
            cols: 12,
            label: 'Confirme a senha',
            dinamicRules: [(v) => !!v || "Preencha a confirmação de senha", (v) => v==this.inputsValue.password || "As senhas não conferem"],
            disabled: false,
            outlined: false,
            valueField: this.inputsValue.confirm_password,
          },
        }
    },
    getUser(id) {
      this.text_button = 'Salvar'

      let obj = {
        id: id
      }
      this.GET_USER(obj)
          .then( res => {
            this.inputsValue = res.data
            this.initFields()
          }
      )
    },
    sendUser(event) {
      let obj = {
        key: this.$route.params.key,
        password: event.values.confirm_password,
      }
      if (this.inputsValue.password == this.inputsValue.confirm_password) {
        axios.post('/admin/create-password', obj)
          .then(response => {
            if (res.status === 200) {
              this.$router.push({name: 'Login'})
            }
          })
          .catch(error => error)
      }
    }
  }
}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 50%;
}

.card {
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.logo{
  width: 300px;
}

.headerLogo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #0074F0;
  height: 5.3rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
}
</style>