<template>
  <menu>
    <div>
      <a @click="$router.push({name:'Template'})" class="img-box">
        <img width="150" src="../../assets/booklab-logo.png"/>
      </a>
      <ul>
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Termos de Uso"
            icon="mdi-book-outline"
            routeName="TermsOfUse"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Grupo"
            icon="mdi-account-group-outline"
            routeName="Groups"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Usuários"
            icon="mdi-account-outline"
            routeName="Users"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Dentistas"
            icon="mdi-doctor"
            routeName="DentistList"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Integrações"
            icon="mdi-account-arrow-right-outline"
            routeName="Integrations"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Formas de Pagamento"
            icon="mdi-cash"
            routeName="Payments"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Bancos"
            icon="mdi-bank-outline"
            routeName="Banks"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Dentes"
            icon="mdi-tooth-outline"
            routeName="Teeths"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Laboratórios"
            icon="mdi-hospital-box-outline"
            routeName="LabList"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Status"
            icon="mdi-order-bool-descending-variant"
            routeName="OrderStatus"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Pedidos"
            icon="mdi-clipboard-list-outline"
            routeName="Orders"
        />
        <ItemMenu
            :clear="selected"
            @selected="selected = $event"
            label="Tabela de Cores"
            icon="mdi-invert-colors"
            routeName="ColorTable"
        />
      </ul>
    </div>
    <LogoutMenu
        :clear="selected"
        @selected="selected = $event"
        @loggout="logout"
        label="Sair"
        icon="mdi-close"
        :logout="true"
        routeName="LoggedOut"
    />
  </menu>
</template>

<script>


import {mapActions} from "vuex";
import ItemMenu from "./ItemMenu";
import LogoutMenu from "./LogoutMenu";

export default {
  name: 'MenuBar',
  components: {
    ItemMenu,
    LogoutMenu
  },
  data() {
    return {
      mini: false,
      selected: null,
    }
  },
  props: {
    menu: {
      Type: Boolean,
      Default: false
    }
  },
  mounted() {
    this.mini = this.menu
  },
  methods: {
    ...mapActions(['LOGOUT_PROCESS']),
    logout() {
      this.LOGOUT_PROCESS()
      this.$router.push({name: 'Login'})
    }
  },
  watch: {
    mini : {
      handler(){
        this.$emit('mini', this.mini)
      }
    },
    menu: {
      handler(){
        this.mini = this.menu;
      }
    }
  },
}

</script>


<style scoped>

.list-item-group {
  /*margin-top: 6rem;*/
}

.v-list-item--active {
  background-color: rgba(60, 46, 136, 0.83) !important;
}

v-card {
  border-radius: 0;
}


.cor-texto {
  color: white;
}

.menu {
  height: 100%;
  display: flex;
}

.text {
  display: flex;
  margin-left: 10%;
  color: white;
}

.titulo {
  display: flex;
  flex-direction: row;
}

.imagem {
  width: 100%;
  padding: 0em 0.2em 0.2em 0.2em;
  webkit-transition: width 1s;
  transition: width 1s;
}

.list-img {
  height: 200px;
  background-color: white !important;
  display: flex;
  justify-content: center;

}

.divider {
  background-color: #0074F0;
  margin-top: 20px;
}

menu {
  background: #0074F0;
  width: 320px;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  -webkit-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
  -moz-box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
  box-shadow: 1px 0px 3px 0px rgba(212, 212, 212, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  overflow-x: hidden;
  z-index: 10;
}

.img-box {
  height: 95px;
  /*margin-right: 30px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-float {
  padding: 0 20px;
  display: flex;
  margin: 10px 10px 30px 10px;
  border-radius: 30px;
  height: 48px !important;
  background: #ffffff;
  cursor: pointer;
}

.logout {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.view {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.slot {
  min-width: 75%;
  min-height: 75%;
}
</style>