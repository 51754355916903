<template>
    <div class="orders-screen">
        <div class="data-table">
        <GenericDataTable
          :blocos="true"
          class="mt-10"
          :header="header"
          :sortBy="[{key: 'id', order: 'desc'}]"
          detailsUrl="/admin/"
          :action="'GET_ORDERS'"
          :addBtn="false"
          :addEditDelete="false"
          :addShow="true"
          icon="mdi-clipboard-list-outline"
          :filters="filters"
          :pequeno="false"
          :grande="true"
          :addSearch="true"
          :addsortBy="true"

          @show="show($event)"
        />
        </div>
    </div>
</template>

<script>
//import OrdersDataTable from "../../components/DataTable/OrdersDataTable";
import GenericDataTable from "../../components/DataTable/GenericDataTable";


export default ({
  name: 'Orders',
  components: {
      //OrdersDataTable,
      GenericDataTable,

  },
  data() {
    return {
      singleExpand: false,
      access_token: '',
      creation_data: '',
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "id",
          sortable: false,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Paciente",
          sortable: true,
          filterable: true,
          value: "patient.name",
          align: "left",
        },
        {
          text: "E-mail paciente",
          sortable: true,
          filterable: true,
          value: "patient.email",
          align: "left",
        },
        {
          text: "Dentista",
          sortable: true,
          filterable: true,
          value: "dentist.user.name",
          align: "center",
        },
        {
          text: "E-mail dentista",
          sortable: true,
          filterable: true,
          value: "dentist.user.email",
          align: "left",
        },
        {
          text: "Categoria",
          sortable: true,
          filterable: true,
          value: "laboratory_service.service.category.name",
          align: "center",
        },
        {
          text: "Serviço",
          sortable: true,
          filterable: true,
          value: "laboratory_service.service.name",
          align: "center",
        },
        {
          text: "Data",
          sortable: true,
          filterable: true,
          value: "created_at",
          align: "center",
        },
        {
          text: "Entrega",
          sortable: true,
          filterable: true,
          value: "delivery_time",
          align: "center",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          filterable: false,
          value: "options",
        },
      ],
      

      filters: {},
      item: {}


      // {
      //   id: '',
      //   evidence: '',
      //   delivery_time: '',
      //   delivery_period: '',
      //   delivery_address_street: '',
      //   delivery_address_number: '',
      //   patient: '',
      //   patient_email: '',
      //   dentist: '',
      //   dentist_email: '',
      //   dentist_cro: '',
      //   category: '',
      //   service: '',
      // }

    }
  },

  mounted(){
  },

  methods: {
    show(id) {
      localStorage.showId = id
      this.$router.push({name: 'OrderDetails', params: {id: id}})
      // this.show()
    },
  },

})

</script>

<style scoped>

.orders-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
