import axios from "../../plugins/axios";

export const ordersStore = {
    state: () => ({
        orders: [],
        order: {},
    }),
    mutations: {
        setOrders(state, payload) {
            state.orders = payload;
        },
        
        setOrder(state, payload) {
            state.order = payload;
        },
    },
    actions: {
        GET_ORDERS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/order/list", {params: filters})
                    .then(response => {
                        let values = response.data
                        commit('setOrders', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_ORDER({commit}, id) {
            return new Promise((resolve) => {
                axios.get("/order/"+id+"/find")
                    .then(response => {
                        let values = response.data.data
                        commit('setOrder', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default ordersStore;