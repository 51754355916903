<template>
  <div class="body">
    <div class="body-content">
      <v-card
        height="560"
        elevation="3"
        class="card"
      >
        <div class="header">
          <div style="display: flex;flex-direction: row">
            <v-icon color="white" class="mr-3" size="25">mdi-account-outline</v-icon>
            <h2 style="color: white">
              {{ textHeader }}
            </h2>
          </div>
          <div style="height: 0.2rem; width: 2rem; background-color: #3d3de8" />
        </div>
        <div class="content">
          <FormFields
              :fields="fields"
              :validate="validateFields"
              @update:values="inputsValue = $event"
              @checkValidity="sendUser($event)"
          />
          <div class="buttons mt-5">
            <DefaultButton
                text="Voltar"
                :branco="true"
                @click.native="$router.back()"
            />
            <DefaultButton
              :text="text_button"
              :azul="true"
              class="ml-4"
              @click.native="validateFields++"
            />
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>

import FormFields from "../../components/FormFields";
import DefaultButton from "../../components/Buttons/DefaultButton";
import {mapActions} from "vuex";

export default {
  name: 'CreateUser',
  components: {DefaultButton, FormFields},
  data() {
    return {
      fields: {},
      validateFields: 0,
      textHeader: 'Cadastro de Usuário',
      inputsValue: {
        id: null,
        name: null,
        email: null,
        group: null,
      },
      text_button: 'Cadastrar',
      flag: false,
      groups: [],
    }
  },
  props: {
    isEditing: {
      default: false
    }
  },
  async mounted() {
    await this.getGroups()
    if(this.$route.params.isEditing) {
      this.getUser(this.$route.params.id)
    }else {
      await this.initFields()
    }
  },
  methods: {
    ...mapActions(['GET_USER', 'CREATE_USER', 'EDIT_USER', 'GET_GROUPS']),
    async getGroups() {
      await this.GET_GROUPS().then( res => {
        this.groups = res.data.data
      })
    },
    initFields() {
      if (this.$route.params.isEditing) {
        this.fields = {
          name: {
            identificacao_interna: 'name',
            field: 'TextField',
            cols: 12,
            label: 'Nome',
            dinamicRules: [(v) => !!v || "Preencha o nome"],
            disabled: false,
            outlined: false,
            valueField: this.inputsValue.name,
          },
          email: {
            identificacao_interna: 'email',
            field: 'TextField',
            cols: 12,
            label: 'E-mail',
            outlined: false,

            dinamicRules: [(v) => !!v || "Preencha o username"],
            disabled: false,
            valueField: this.inputsValue.email,
          },
          group: {
            identificacao_interna: 'group',
            field: 'SelectField',
            cols: 12,
            items: this.groups,
            keyValue: 'id',
            keyName: 'name',
            outlined: false,
            label: 'Grupo',
            dinamicRules: [(v) => !!v || "Preencha o grupo"],
            valueField: this.inputsValue.group,
          },
        }
      } else {
        this.fields = {
          name: {
            identificacao_interna: 'name',
            field: 'TextField',
            cols: 12,
            label: 'Nome',
            dinamicRules: [(v) => !!v || "Preencha o nome"],
            disabled: false,
            outlined: false,
            valueField: this.inputsValue.name,
          },
          email: {
            identificacao_interna: 'email',
            field: 'TextField',
            cols: 12,
            label: 'E-mail',
            outlined: false,

            dinamicRules: [(v) => !!v || "Preencha o username"],
            disabled: false,
            valueField: this.inputsValue.email,
          },
          // senha: {
          //   identificacao_interna: 'senha',
          //   field: 'TextField',
          //   cols: 12,
          //   label: 'Senha',
          //   type: 'password',
          //   outlined: false,
          //   dinamicRules: [(v) => !!v || "Preencha a senha"],
          //   disabled: false,
          //   valueField: this.inputsValue.senha,
          // },
          group: {
            identificacao_interna: 'group',
            field: 'SelectField',
            cols: 12,
            items: this.groups,
            keyValue: 'id',
            keyName: 'name',
            outlined: false,
            label: 'Grupo',
            dinamicRules: [(v) => !!v || "Preencha o grupo"],
            valueField: this.inputsValue.group,
          },
        }
      }

    },
    getUser(id) {
      this.text_button = 'Salvar'

      let obj = {
        id: id
      }
      this.GET_USER(obj)
          .then( res => {
            this.inputsValue = res.data
            this.initFields()
          }
      )
    },
    sendUser(event) {
      let obj = {
        id: this.$route.params.id,
        name: event.values.name,
        email: event.values.email,
        user_type_id: 0,
        group_id: event.values.group.id
      }
      if (!this.$route.params.isEditing) {
        this.CREATE_USER(obj)
            .then( res => {
              if (res.status === 201) {
                this.$router.push({name: 'Users'})
              }
            })
            .catch( error => {
              console.log(error)
              this.flag = true
            })
      }else {
        this.EDIT_USER(obj).then( res => {
          if (res.status === 200) {
            this.$router.push({name: 'Users'})
          }
        })
          .catch( error => {
            console.log(error)
            this.flag = true
          })
      }

    }
  }
}
</script>

<style scoped>

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.body .body-content {
  width: 90%;
}

.card {
  /*padding: 0 2rem;*/
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #3d3de8;
  height: 3.5rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
}
</style>