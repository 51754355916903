<template>
  <div class="background-card" :class="{grande: grande, pequeno: pequeno}">
    <div style="width: 100%;">

      <v-data-table
          :headers="header"
          :items="tableData"

          :search="search"
          :sort-by="'position'"

          :items-per-page="999"
          class="elevation-1"

          @pagination="getData"
          hide-default-footer
          hide-default-header
      >

        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="(h, index) in headers" :key="h+index" class="test">
              <h4 style="text-align: center">{{h.text}}</h4>
            </th>
          </tr>
          </thead>
        </template>


        <template v-slot:top>
          <v-toolbar
              flat
          >
            <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                color="primary"
            ></v-text-field> -->
            <v-spacer />
            <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="$router.push({name: routeCreate })"
            >
              Cadastrar Novo
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }" class="drop-zone" >
          <tr draggable class="drag-el" @dragstart="startDrag($event, item)" @drop="onDrop($event, item.position)" @dragover.prevent @dragenter.prevent>
            <td class="text-center">
              <div class="buttons">
                <v-icon
                    size="24"
                    color="primary"
                >
                  {{ icon }}
                </v-icon>
              </div>
            </td>
            <td class="text-center">{{item.position}}</td>
            <td class="text-center">{{item.id}}</td>
            <td>{{item.name}}</td>
            <td class="text-center">
              <div class="buttons">
              <v-btn
                  fab
                  text
                  small
                  color="white"
                  @click.native="clickOptions(item.id)"
              >
                <v-icon
                    size="24"
                    color="grey"
                    class="icone"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-dialog
                transition="dialog-top-transition"
                max-width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    text
                    small
                >
                  <v-icon
                      size="24"
                      color="red"
                      class="icone"
                  >
                    mdi-delete-outline
                  </v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark>
                    </v-toolbar>
                    <v-card-text class="pa-1">
                      <div class="text-h5 pa-5 text-center">Você realmente deseja excluir?</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.value = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="dialog.value = false"
                        @click.native="deleteOptions(item)"
                      >
                        Excluir
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
            </td>
          </tr>
        </template>


        <template v-slot:item.options="{ item }">
          <div class="buttons">
            <v-btn
                fab
                text
                small
                color="white"
                @click.native="clickOptions(item.id)"
            >
              <v-icon
                  size="24"
                  color="grey"
                  class="icone"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-dialog
              transition="dialog-top-transition"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  text
                  small
              >
                <v-icon
                    size="24"
                    color="red"
                    class="icone"
                >
                  mdi-delete-outline
                </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar
                    color="primary"
                    dark>
                  </v-toolbar>
                  <v-card-text class="pa-1">
                    <div class="text-h5 pa-5 text-center">VocÃª realmente deseja excluir?</div>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog.value = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="dialog.value = false"
                      @click.native="deleteOptions(item.id)"
                    >
                      Excluir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
        </template>


        <template v-slot:no-data>
          <p>Sem dados</p>
        </template>


      </v-data-table>

      <div class="text-center pt-2 mt-5 mb-5">
        <v-pagination
            v-model="page"
            :length="totalPage"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import {mapActions} from "vuex";
import axios from "../../plugins/axios";

export default {
  name: 'GenericDataTable',
  components: {

  },
  data() {
    return {
      search: '',
      page: 1,
      totalPage: 1,
      tableData: [],

      list: []
    }
  },
  filters: {
    horaFormat(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY") + ' ' + value.split('.', 1)[0].split(' ')[1]
      }
      return value
    },
    semHora(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY")
      }
      return value
    }
  },
  props: {
    action: {},
    header: {},
    filters: {},
    perPage: {
      type: Number,
      default: 20,
    },
    refresh: {},

    routeCreate: {},
    routeEdit: {},
    icon: {},
    grande: {},
    pequeno: {},

    blocos: {},
    fornecedores: {},
  },

  computed: {
    listOne() {
      return this.items.filter((item) => item.list === 1)
    },
    listTwo() {
      return this.items.filter((item) => item.list === 2)
    },
  },

  methods: {
    ...mapActions(['GET_EXCEL']),

    getData(){
      this.$store.dispatch(this.action).then(res => {
        this.tableData = res.data.data;
        this.totalPage = res.data.last_page ? res.data.last_page : res.data.meta.last_page;
        window.scroll({top: 0, behavior: "smooth"})
      });
    },

    clickOptions(id) {
      this.$emit('options', id)
    },
    deleteOptions(id) {
      this.$emit('delete', id)
    },

    geraExcel() {
      let filters = {
        ...this.filters,
      }
      this.GET_EXCEL(filters).then(response => {
        let blob = new Blob([response.data], {type: ""})
        this.forceDownload(window.URL.createObjectURL(blob))
      })
    },
    forceDownload(data) {
      let link = document.createElement('a')
      link.href = data
      link.download = 'Log_CRM.xlsx'
      link.click()
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.id)
    },
    onDrop(evt, order) {
      const itemID = evt.dataTransfer.getData('itemID')
      const item = this.tableData.find((item) => item.id == itemID)
      console.log(this.tableData)
      var anterior = item.position
      if(anterior>order){
        for(var i=0 ; i<this.tableData.length ; i++){
          if(this.tableData[i].position < anterior){
            if(this.tableData[i].position >= order){
              this.tableData[i].position += 1
              axios.put('/order/order-status/' + this.tableData[i].id, this.tableData[i])
              .then(response => {
                  let values = response.data.data
                  commit('setTerm', values);
                  resolve(response)
              })
              .catch(error => error)
            }
          }
        }
        item.position = order
        axios.put('/order/order-status/' + item.id, item)
            .then(response => {
                let values = response.data.data
                commit('setTerm', values);
                resolve(response)
            })
            .catch(error => error)
      }else{
        for(var i=0 ; i<this.tableData.length ; i++){
          if(this.tableData[i].position > anterior){
            if(this.tableData[i].position <= order){
              this.tableData[i].position -= 1
              axios.put('/order/order-status/' + this.tableData[i].id, this.tableData[i])
              .then(response => {
                  let values = response.data.data
                  commit('setTerm', values);
                  resolve(response)
              })
              .catch(error => error)
            }
          }
        }
        item.position = order
        axios.put('/order/order-status/' + item.id, item)
            .then(response => {
                let values = response.data.data
                commit('setTerm', values);
                resolve(response)
            })
            .catch(error => error)
      }
    },
  },

  computed: {
    // array_filter() {
    //   return this.tableData.filter(item_list => {
    //     return item_list.email.toLowerCase().includes(this.search.toLowerCase())
    //         // item_list.classification.toString().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').includes(this.search)
    //   })
    // }
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    refresh: {
      handler() {
        this.getData();
      }
    },
  }


}
</script>


<style scoped>
h5 {
  font-weight: normal;
  font-size: 0.8rem;
  height: 100%;
  margin-left: 0.5rem;
}


.retangular-button {
  box-shadow:  0 0 0.1em black !important;
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.icone:hover {
  color: #3c2e88 !important;
}

.lixo:hover {
  color: red !important;
}

.grande {
  width: 95%;
}

.pequeno {
  width: 50%;
}

.updated_at_class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

</style>
