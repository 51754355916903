import axios from "../../plugins/axios";

export const dentistsStore = {
    state: () => ({
        dentists: [],
        dentist: {},
    }),
    mutations: {
        setDentists(state, payload) {
            state.dentists = payload;
        },
        setDentist(state, payload) {
            state.dentist = payload;
        },
    },
    actions: {
        GET_DENTISTS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/dentists/users", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setDentists', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_DENTIST({commit}, id) {
            return new Promise((resolve) => {
                axios.get("/dentists/users/"+id)
                    .then(response => {
                        let values = response.data.data
                        commit('setDentist', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
    },
    getters: {}
}

export default dentistsStore;