import axios from "../../plugins/axios";

export const bankStore = {
    state: () => ({
        banks: [],
        bank: {},
    }),
    mutations: {
        setBank(state, payload) {
            state.bank = payload;
        },
        setBanks(state, payload) {
            state.banks = payload;
        },
    },
    actions: {
        GET_BANKS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/admin/banks", {params: filters})
                    .then(response => {
                        let values = response.data.data
                        commit('setBanks', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_BANK({commit}, obj) {
            return new Promise((resolve) => {
                axios.get('/admin/banks/' + obj.id)
                    .then(response => {
                        let values = response.data.data
                        commit('setBank', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_BANK({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/banks', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setBank', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_BANK({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/banks/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setBank', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_BANK(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/banks/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default bankStore;