<template>
  <div class="background-card" :class="{grande: grande, pequeno: pequeno}">
    <div style="width: 100%;">

      <v-data-table
          v-model:sort-by="sortBy"
          :headers="header"
          :items="tableData"



          :search="search"

          :server-items-length="totalPage"
          :page="page"
          :items-per-page="1"
          class="elevation-1"

          @pagination="getData"
          hide-default-footer
          hide-default-header
      >

        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="(h, index) in headers" :key="h+index" class="test">
              <h4 style="text-align: center">{{h.text}}</h4>
            </th>
          </tr>
          </thead>
        </template>


        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
                color="primary"
                v-show="addSearch"
            ></v-text-field>
            <v-spacer />
            <v-btn
                v-show="addBtn"
                color="primary"
                class="ma-2 white--text"
                @click="$router.push({name: routeCreate })"
            >
              Cadastrar Novo
            </v-btn>
          </v-toolbar>
        </template>


        <template v-slot:item.avatar="{}">
          <div class="buttons">
            <v-icon
                size="24"
                color="primary"
            >
              {{ icon }}
            </v-icon>
          </div>
        </template>

        <template v-slot:item.updated_at="{ item }">
          <div class="updated_at_class">
            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.85rem; font-weight: normal" >{{ item.updated_at | semHora}}</h4>

          </div>
        </template>

        <template v-slot:item.standard_image="{ item }">
              <img :src="item.standard_image" style="width: 100px; height: 100px;">
        </template>

<!--        <template v-slot:item.created_at="{ item }">-->
<!--          <div style="display: flex; flex-direction: column">-->
<!--            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Processado: <h5 style="font-weight: normal;font-size: 0.8rem; height: 100%; margin-left: 0.5rem">{{ item.created_at | horaFormat}}</h5></h4>-->
<!--            <h4 style="display: flex; flex-direction: row; align-items: center; font-size: 0.8rem" >Recebido: <h5 style="">{{ item.received_at | semHora}}</h5></h4>-->
<!--          </div>-->
<!--        </template>-->

        <template v-slot:item.fields="{ item }">
          <div>

            <h4>{{item.name}}</h4>

          </div>
        </template>


        <template 
        v-slot:item.options="{ item }">
          <div 
          class="buttons" 
          v-show="addEditDelete">
            <v-btn
                fab
                text
                small
                color="white"
                @click.native="clickOptions(item.id)"
            >
              <v-icon
                  size="24"
                  color="grey"
                  class="icone"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-dialog
              transition="dialog-top-transition"
              max-width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  text
                  small
              >
                <v-icon
                    size="24"
                    color="red"
                    class="icone"
                >
                  mdi-delete-outline
                </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar
                    color="primary"
                    dark>
                  </v-toolbar>
                  <v-card-text class="pa-1">
                    <div class="text-h5 pa-5 text-center">Você realmente deseja excluir?</div>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog.value = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="dialog.value = false"
                      @click.native="deleteOptions(item.id)"
                    >
                      Excluir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
          <div
          class="buttons" 
          v-show="addShow"
          >
            <v-btn
                fab
                text
                small
                color="white"
                @click.native="clickShow(item.id)"
            >
              <v-icon
                  size="24"
                  color="grey"
                  class="icone"
              >
                mdi-file-eye-outline
              </v-icon>
            </v-btn>
          </div>
          <div
          class="buttons" 
          v-show="addLupa"
          >
            <v-btn
                fab
                text
                small
                color="white"
                @click.native="clickLupa(item.user_id)"
            >
              <v-icon
                  size="24"
                  color="grey"
                  class="icone"
              >
                mdi-magnify-scan
              </v-icon>
            </v-btn>
          </div>
        </template>


        <template v-slot:no-data>
          <p>Sem dados</p>
        </template>


      </v-data-table>

      <div class="text-center pt-2 mt-5 mb-5">
        <v-pagination
            v-model="page"
            :length="totalPage"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import {mapActions} from "vuex";

export default {
  name: 'GenericDataTable',
  components: {

  },
  data() {
    return {
      search: '',
      page: 1,
      totalPage: 1,
      tableData: [],
      camposIntegracao: [],
      list: []
    }
  },
  filters: {
    horaFormat(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY") + ' ' + value.split('.', 1)[0].split(' ')[1]
      }
      return value
    },
    semHora(value) {
      if (value){
        return moment(value.split('.', 1)[0].split(' ')[0]).format("DD/MM/YYYY")
      }
      return value
    }
  },
  props: {
    action: {},
    header: {},
    sortBy: [],
    addBtn: {
      default: true,
    },
    addEditDelete: {
      default: true
    },
    addShow: {
      default: false
    },
    addLupa: {
      default: false
    },
    addSearch: {
      default: false
    },
    addsortBy: {
      default: false
    },
    filters: {},
    perPage: {
      type: Number,
      default: 20,
    },
    refresh: {},

    routeCreate: {},
    routeEdit: {},
    icon: {},
    grande: {},
    pequeno: {},

    blocos: {},
    fornecedores: {},
  },

  methods: {
    ...mapActions(['GET_EXCEL']),

    getData(){
      let filters
      if(this.search == ''){
        filters = {
          ...this.filters,
          per_page: this.perPage,
          page: this.page,
        }
      }else{
        filters = {
          ...this.filters,
          search: this.search,
        }
      }
      this.$store.dispatch(this.action, filters).then(res => {
        this.tableData = res.data.data ?? res.data;
        for(var i=0 ; i<this.tableData.length ; i++){
          if(this.tableData[i].fields != undefined){
            this.tableData[i].fields = JSON.stringify(this.tableData[i].fields[0]);
            //this.tableData[i].fields = JSON.parse(this.tableData[i].fields)
            //console.log('aqui', this.tableData[i].fields)
          }
        }
        //console.log(this.tableData)
        this.totalPage = res.data.last_page ? res.data.last_page : res.data.meta.last_page;
        window.scroll({top: 0, behavior: "smooth"})
      });
    },

    clickOptions(id) {
      this.$emit('options', id)
    },
    deleteOptions(id) {
      this.$emit('delete', id)
    },
    clickShow(id) {
      this.$emit('show', id)
    },

    geraExcel() {
      let filters = {
        ...this.filters,
      }
      this.GET_EXCEL(filters).then(response => {
        let blob = new Blob([response.data], {type: ""})
        this.forceDownload(window.URL.createObjectURL(blob))
      })
    },
    forceDownload(data) {
      let link = document.createElement('a')
      link.href = data
      link.download = 'Log_CRM.xlsx'
      link.click()
    },
  },

  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    refresh: {
      handler() {
        this.getData();
      }
    },
    search: {
      handler() {
        clearTimeout(this._searchTimerId)
          this._searchTimerId = setTimeout(() => {
           this.getData();
        }, 500)
      }
    },
  }


}
</script>


<style scoped>
h5 {
  font-weight: normal;
  font-size: 0.8rem;
  height: 100%;
  margin-left: 0.5rem;
}


.retangular-button {
  box-shadow:  0 0 0.1em black !important;
  display: flex;
  font-size: 0.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.icone:hover {
  color: #3c2e88 !important;
}

.lixo:hover {
  color: red !important;
}

.grande {
  width: 95%;
}

.pequeno {
  width: 50%;
}

.updated_at_class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
