import axios from "../../plugins/axios";

export const integrationStore = {
    state: () => ({
        integrations: [],
        integration: {},
    }),
    mutations: {
        setIntegration(state, payload) {
            state.integration = payload;
        },
        setIntegrations(state, payload) {
            state.integrations = payload;
        },
    },
    actions: {
        GET_INTEGRATIONS({commit}, filters) {
            return new Promise((resolve) => {
                axios.get("/admin/integration", {params: filters})
                    .then(response => {
                        let values = response.data
                        for(var i=0 ; i<values.length ; i++){
                            values[i].fields = values[i].fields.split("','")

                            values[i].fields[0] = values[i].fields[0].substring(1);

                            values[i].fields[values[i].fields.length-1] = values[i].fields[values[i].fields.length-1].substring(0,values[i].fields[values[i].fields.length-1].length -1);
                        }
                        commit('setIntegrations', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        GET_INTEGRATION({commit}, obj) {
            console.log('1')
            return new Promise((resolve) => {
                axios.get('/admin/integration/' + obj.id)
                    .then(response => {
                        let values = response.data
                        commit('setIntegration', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },
        CREATE_INTEGRATION({commit}, obj) {
            return new Promise((resolve) => {
                axios.post('/admin/integration', obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setIntegration', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        EDIT_INTEGRATION({commit}, obj) {
            return new Promise((resolve) => {
                axios.put('/admin/integration/' + obj.id, obj)
                    .then(response => {
                        let values = response.data.data
                        commit('setIntegration', values);
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

        DELETE_INTEGRATION(context, id) {
            return new Promise((resolve) => {
                axios.delete('/admin/integration/' + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => error)
            })
        },

    },
    getters: {}
}

export default integrationStore;