import Vue from 'vue'
import VueRouter from 'vue-router'
import loginRouter from './modules/login'
import booklab_portal from "./modules/booklab_portal";

Vue.use(VueRouter)

const routes = [
    ...loginRouter,
    ...booklab_portal
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token') == null) {
    // if (to.matched.some(record => record?.meta?.requiresAuthTitular)) {
    //
    //   next({
    //     path: '/login',
    //     query: {
    //       token: to.query.token,
    //       nextUrl: to.path,
    //       tipo: to.query.tipo || 'titular'
    //     }
    //   })
    // }

    if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
      next({
        path: '/login',
        // query: {
        //   token: to.query.token,
        //   nextUrl: to.path,
        //   tipo: 'admin'
        // }
      })
    }

  } else {

    //verifica se tem alguma validação, se não tiver segue pra rota
    if (!Object.keys(to.meta).length) {
      if (to.path === '/login') {
        next( {
          path: '/users'
        })
        return;
      }
      next();
      return;
    }

    let authRoute = {
      'admin': () => {
        if (to.meta.requiresAuthAdmin || to.name === 'LoggedOut') {
          next();
        } else {
          next({
            name: 'Users'
          })
        }
      },
      // 'patrocinadora': () => {
      //   if (to.meta.requiresAuthPatrocinadora) {
      //     next();
      //   } else {
      //     next({
      //       name: 'UploadArquivo'
      //     })
      //   }
      // }
    }


    let role = localStorage.getItem('role');
    authRoute[role]();

  }

  next()
})

export default router
