<template>
  <div class="integrations-screen">
    <div class="data-table">
      <GenericDataTable
          :blocos="true"
          class="mt-10"
          :header="header"
          detailsUrl="/admin/"
          :action="'GET_INTEGRATIONS'"
          :refresh="refreshTable"
          routeCreate="CreateIntegration"
          routeEdit="EditIntegration"
          icon="mdi-merge"
          :filters="filters"
          :pequeno="false"
          :grande="true"
          @options="edit($event)"
          @delete="deletar($event)"
      />
      <modal
          name="email-modal"
          width="800"
          height="700"
          :clickToClose="false"
          :adaptive="true"
          class="email-modal"
      >
        <!--<Modal @closeModal="hide($event)" :item="item"/>-->
      </modal>
    </div>
  </div>
</template>


<script>
//import Modal from "../../components/Modal/Modal";
import GenericDataTable from "../../components/DataTable/GenericDataTable";
import {mapActions} from "vuex";

export default {
  name: 'Integrations',
  components: {
    GenericDataTable,
    //Modal
  },
  data() {
    return {
      header: [
        {
          text: "",
          sortable: true,
          filterable: true,
          value: "avatar",
          align: "center",
        },
        {
          text: "id",
          sortable: true,
          filterable: true,
          value: "id",
          align: "center",
        },
        {
          text: "Integração",
          sortable: true,
          filterable: true,
          value: "name",
          align: "left",
        },
        {
          text: "Opções",
          align: "center",
          sortable: false,
          filterable: false,
          value: "options",
        },
      ],
      refreshTable: 0,
      filters: {},
      item: {}
    }
  },
  methods: {
    ...mapActions(['GET_INTEGRATION', 'DELETE_INTEGRATION']),

    edit(id) {
      this.$router.push({name: 'EditIntegration', params: {id: id, isEditing: true}})
      //this.show()
    },
    deletar(id) {
      this.DELETE_INTEGRATION(id).then(
        () => {
          this.refreshTable++
        }
      )
    },
    show() {
      this.$modal.show('email-modal');
    },
    hide(value) {
      this.$modal.hide('email-modal');
      if (value) {
        this.alert = true;
        this.refreshTable++;
      }
    }
  }
}
</script>

<style scoped>
.integrations-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>